<template>
  <div class="wrapper contractsApproval">
    <el-row class="main approval">
      <aside class="menu-expanded" v-show="!collapsed">
        <!--导航菜单-->
        <div class="menu">
          <el-collapse v-model="activeNames" @change="handleChange">
            <el-collapse-item title="" name="1">
              <template slot="title"
                ><div class="subTit">
                  <el-icon class="el-icon-files"></el-icon>快捷菜单
                </div></template
              >
              <ul class="menuList">
                <li
                  @click="searchCus(item.val, 'quick')"
                  v-for="item in menuList"
                  :class="actived == item.val ? 'actived' : ''"
                  :key="item.val"
                >
                  {{ item.name }}
                </li>
              </ul>
            </el-collapse-item>
          </el-collapse>
        </div>
      </aside>
      <section
        v-loading="screenLoading"
        class="content-container clearfix scollDom"
        :class="collapsed ? 'screen' : ''"
      >
        <el-col :span="24">
          <el-row class="searchBox">
            <el-form @submit.native.prevent :model="filter" ref="filter">
              <div class="fl searchL">
                <el-form-item label style="margin-bottom: 20px">
                  <el-input
                    class="search"
                    v-model="filter.filter"
                    @keyup.enter.native="submitForm('filter')"
                    placeholder="请输入客户名称 / 合同名称 / 合同编号 / 创建人，支持模糊搜索"
                  >
                    <el-button
                      type="primary"
                      slot="append"
                      @click="submitForm('filter')"
                      >搜 索</el-button
                    >
                  </el-input>
                </el-form-item>
              </div>
              <div class="other">
                <span class="more" @click="showMore = !showMore"
                  >{{ showMore ? "收起搜索" : "展开搜索"
                  }}<el-icon
                    :class="
                      showMore ? 'el-icon-arrow-up' : 'el-icon-arrow-down'
                    "
                  ></el-icon>
                </span>
              </div>
            </el-form>
            <div class="searchM" v-show="showMore">
              <el-form
                @submit.native.prevent
                :model="filter"
                ref="filter"
                class="clearfix"
                label-width="85px"
              >
                <el-form-item label="客户：" prop="companyName">
                  <el-input
                    placeholder="请输入客户"
                    v-model="filter.companyName"
                    class="searchInput numberHeight"
                    style="width: 240px"
                  >
                    <template slot="suffix">
                      <el-icon
                        class="el-icon-search"
                        @click.native="submitForm('filter')"
                      ></el-icon>
                    </template>
                  </el-input>
                </el-form-item>
                <el-form-item label="合同名称：" prop="name">
                  <el-input
                    placeholder="请输入合同名称"
                    v-model="filter.name"
                    class="searchInput numberHeight"
                    style="width: 240px"
                  >
                    <template slot="suffix">
                      <el-icon
                        class="el-icon-search"
                        @click.native="submitForm('filter')"
                      ></el-icon>
                    </template>
                  </el-input>
                </el-form-item>
                <el-form-item label="创建人：" prop="createdUserIds">
                  <el-select
                    v-model="filter.createdUserIds"
                    placeholder="请选择"
                    style="width: 240px"
                    filterable
                    clearable
                    multiple
                    size="mini"
                    collapse-tags
                    @change="submitForm('filter')"
                    :filter-method="filterFun"
                  >
                    <el-option
                      v-for="i in userList"
                      :key="i.id"
                      :label="i.name"
                      :value="i.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="审批ID：" prop="approvalItemId">
                  <el-input
                    placeholder="请输入审批ID"
                    v-model="filter.approvalItemId"
                    class="searchInput numberHeight"
                    style="width: 240px"
                  >
                    <template slot="suffix">
                      <el-icon
                        class="el-icon-search"
                        @click.native="submitForm('filter')"
                      ></el-icon>
                    </template>
                  </el-input>
                </el-form-item>
              </el-form>
            </div>
          </el-row>
          <el-row>
            <el-tabs v-model="activeName" @tab-click="handleClick">
              <el-tab-pane
                :label="'待审批' + count.approvalCount"
                name="待审批"
                id="contractsApprovalApproval"
              ></el-tab-pane>
              <el-tab-pane
                v-if="isshow"
                :label="'待盖章' + count.sealCount"
                name="待盖章"
                id="contractsApprovalSeal"
              ></el-tab-pane>
              <el-tab-pane
                v-if="isshow"
                :label="'待归档' + count.uploadCount"
                name="待归档"
                id="contractsApprovalUpload"
              ></el-tab-pane>
            </el-tabs>
          </el-row>
          <el-row :gutter="24" class="pd40" style="padding-bottom: 10px">
            <el-col :span="24">
              <el-pagination
                :hide-on-single-page="filter.page.total > 10 ? false : true"
                v-if="filter.page.total > 0"
                style="padding-bottom: 10px; padding-top: 10px"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="filter.page.current"
                :page-size="filter.page.size"
                :pager-count="5"
                layout="total,prev, pager, next,sizes"
                :total="filter.page.total"
                :page-sizes="[20, 50, 100]"
              ></el-pagination>
              <el-table
                :header-cell-style="{
                  'background-color': '#f5f7fa',
                  height: '35px',
                  'border-top': '1px #e5e5e5 solid',
                }"
                :data="ListData"
                v-horizontal-scroll
                v-sticky="{ top: 0, parent: '.scollDom' }"
                class="tabBorder custor"
                v-loading="listLoading"
                ref="tableList"
                style="border-top: 0"
              >
                <el-table-column
                  prop="id"
                  label="审批ID"
                  width="80"
                  align="center"
                >
                </el-table-column>
                <el-table-column prop="status" label="合同状态" width="130">
                  <template slot-scope="scope">
                    <span>{{ scope.row.status }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="name"
                  width="230"
                  show-overflow-tooltip
                  label="合同名称"
                >
                  <template slot-scope="scope">
                    <span class="tabHref" @click="handleEdit(scope.row.id)">{{
                      scope.row.name
                    }}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="company" label="客户" width="230">
                  <template slot-scope="scope">
                    <div v-if="scope.row.company.length > 0">
                      <p
                        class="tabHref text-overflows"
                        v-for="i in scope.row.company"
                        :key="i.id"
                        @click="toCustomer(i.id)"
                        :title="i.companyName"
                      >
                        {{ i.companyName }}
                      </p>
                    </div>
                    <span v-else>-</span>
                  </template>
                </el-table-column>
                <el-table-column prop="endDate" label="有效期" width="210">
                  <template slot-scope="scope">
                    <span
                      >{{
                        scope.row.startDate
                          ? scope.row.startDate + " 至 "
                          : scope.row.startDate
                      }}{{
                        scope.row.endDate ? scope.row.endDate : "无期限"
                      }}</span
                    >
                  </template>
                </el-table-column>
                <el-table-column
                  label="签约公司"
                  show-overflow-tooltip
                  width="230"
                >
                  <template slot-scope="scope">
                    <span>{{
                      scope.row.signCompanyName
                        ? scope.row.signCompanyName
                        : "-"
                    }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="creatorUserName"
                  label="创建人"
                  width="130"
                >
                </el-table-column>
                <el-table-column
                  prop="creationTime"
                  label="创建时间"
                  width="210"
                >
                </el-table-column>
                <el-table-column label="操作" width="100" fixed="right">
                  <template #default="scope">
                    <span
                      class="tabHref"
                      @click="handleEdit(scope.row.id)"
                      id="contractsApprovalView"
                      >查看</span
                    >
                  </template>
                </el-table-column>
              </el-table>
              <el-pagination
                :hide-on-single-page="filter.page.total > 10 ? false : true"
                v-if="filter.page.total > 0"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="filter.page.current"
                :page-size="filter.page.size"
                :pager-count="5"
                layout="total,prev, pager, next,sizes"
                :total="filter.page.total"
                :page-sizes="[20, 50, 100]"
              ></el-pagination>
            </el-col>
          </el-row>
        </el-col>
      </section>
    </el-row>
    <!-- 新增合同、查看合同弹窗 -->
    <el-drawer
      :visible.sync="dialogVisible"
      custom-class="drawerClass"
      direction="ttb"
      :modal="false"
      append-to-body
      :before-close="handleClose"
    >
      <addInfo
        ref="saveInfo"
        v-if="isaddInfo"
        @changeSaveLoading="changeSaveLoading"
        @loadList="loadList()"
      ></addInfo>
      <span class="drawer-footer" v-if="isaddInfo" style="float: right">
        <el-button
          type="default"
          plain
          size="mini"
          @click="(dialogVisible = false), cancel()"
          >取消</el-button
        >
        <el-button
          type="primary"
          size="mini"
          :loading="saveloading"
          @click="save()"
          >保存</el-button
        >
      </span>
    </el-drawer>
    <!-- 查看 -->
    <el-drawer
      :visible.sync="isCheckInfo"
      custom-class="drawerClass"
      direction="ttb"
      :modal="false"
      append-to-body
      :before-close="handleClose"
    >
      <div slot="title" class="drawerTitle">
        <i v-if="titName == '合同'" class="drawIcon iconfont icon-ht"></i
        >{{ titName }}
      </div>
      <div class="drawerCon">
        <contractInfo
          v-if="isCheckInfo"
          :contractId="contractId"
          :activeName="activeName"
          @loadList="loadList()"
        ></contractInfo>
      </div>
    </el-drawer>
    <el-drawer
      :visible.sync="customerDrawerVisible"
      custom-class="drawerClass"
      direction="ttb"
      :modal="false"
      append-to-body
      :before-close="handleClose"
    >
      <div slot="title" class="drawerTitle">
        <i class="drawIcon iconfont icon-kh_xq"></i>客户
      </div>
      <div class="drawerCon">
        <customer
          :customId="customId"
          :activeToInfo="activedToInfo"
          @loadList="loadList"
        ></customer>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import {
  getUserBaseInfos, //获取创建人列表
  contractApprovalList, //获取合同审批列表
  isLawApproval, //判断当前审批人是否为法务审批
  GetApprovalCount, //审批数量
} from "../../api/api";
import { mapGetters } from "vuex";
import * as data from "../../common/json/commData.json";
import addInfo from "../../components/contract/addContract";
import contractInfo from "../../components/contract/contractInfo";
import customer from "../../components/customer/customerInfo";
export default {
  name: "contractApproval",
  components: { addInfo, contractInfo, customer },
  data() {
    return {
      activeNames: "1",
      actived: "待审批",
      menuList: [
        {
          name: "待审批",
          val: "待审批",
        },
      ],
      //表单筛选
      filter: {
        filter: "",
        companyName: "",
        name: "",
        approvalItemId: null,
        createdUserIds: [],
        listType: "待审批",
        page: {
          asc: [],
          ascs: [],
          countId: "",
          desc: [],
          descs: [],
          hitCount: true,
          maxLimit: null,
          optimizeCountSql: true,
          orders: [
            {
              asc: true,
              column: "",
            },
          ],
          current: 1,
          size: 50,
          searchCount: true,
          pages: null,
          records: [],
          total: 0,
        },
      },
      showMore: false,
      projectType: data.selectContractStatus, //任务类型
      userList: [],
      userListCopy: [],
      searchFrom: {
        id: null,
        name: "",
        searchList: {},
        type: "合同审批",
        version: 0,
      },
      ListData: [],
      listLoading: false,
      screenLoading: true,
      titName: "",
      isaddInfo: false, //新增合同
      dialogVisible: false,
      dialogWidth: "",
      saveloading: false,
      activeName: "待审批",
      isshow: false,
      count: { approvalCount: 0, sealCount: 0, uploadCount: 0 },
      isCheckInfo: false, //查看
      activedToInfo: "1",
      customerDrawerVisible: false,
      customId: "",
    };
  },
  destroyed() {
    this.canScroll();
  },
  mounted() {
    this.noScroll(); //禁止滚动
  },
  created() {
    if (!this.$route.query.order && !this.$route.query.folder) {
      if (this.$route.query.ownProject) {
        //带参刷新
        this.filter.ownProject = eval(this.$route.query.ownProject);
        if (this.$route.query.status !== "全部合同") {
          this.filter.scheduleStatus = this.$route.query.status;
        }
        this.actived = this.$route.query.status;
      } else {
        //初始默认
        this.filter.ownProject = true;
        this.filter.scheduleStatus = "";
      }
    }

    if (this.$route.query.objectId) {
      //显示合同详情
      this.calendarVisible = true;
      this.calendarTitle = "合同";
      this.projectId = this.$route.query.objectId; //id
    }
    if (this.customerId) {
      this.calendardata.companyId = this.customerId;
      this.loadContactsLitst(this.customerId, "sign");
    }
    this.getIsLawApproval();
    this.loadList();
    this.loadUserBase();
    this.getcount();
  },
  watch: {
    "project.companyIndustryIds"(newValue, oldValue) {
      if (newValue.length > 5) {
        this.$message({
          message: "最多不超过5个",
          duration: 1500,
          type: "error",
        });
        this.$nextTick(() => {
          this.project.companyIndustryIds = oldValue;
        });
      }
    },
    "$route.query"(newValue, oldValue) {
      if (!newValue.order && !newValue.folder) {
        if (newValue.ownProject) {
          this.filter.ownProject = eval(newValue.ownProject);
          this.actived = newValue.status;
          if (newValue.status !== "全部合同") {
            this.filter.scheduleStatus = newValue.status;
          }
        } else {
          this.actived = "我发起的合同";
          this.filter.ownProject = true;
          this.filter.scheduleStatus = null;
        }
        this.loadList();
      }
    },
  },
  computed: {
    ...mapGetters({
      collapsed: "upDateCollapsed",
    }),
  },
  methods: {
    //导航菜单切换
    handleChange() {},
    //侧边栏切换
    searchCus(val, type) {
      if (this.actived != val) {
        this.actived = val;
        this.sortType = "";
        this.sortCheck = null;
        switch (type) {
          case "quick": //快捷菜单
            this.reset();
            this.$router.push({
              path: "/contractApproval",
              query: {
                ownProject: val != "全部合同" ? true : false,
                status: val,
              },
            });
            break;
          case "folder":
            this.$router.push({
              path: "/contractApproval",
              query: {
                folder: val,
              },
            });

            break;
        }
      }
    },
    //切换页数
    handleCurrentChange(val) {
      //currentPage 改变时会触发
      this.filter.page.current = val;
      this.loadList();
    },
    //pageSize 改变时会触发
    handleSizeChange(val) {
      this.filter.page.size = val;
      this.filter.page.current = 1;
      this.loadList();
    },
    //清空
    reset() {
      this.filter = {
        filter: "",
        companyName: "",
        name: "",
        approvalItemId: null,
        createdUserIds: [],
        listType: "待审批",
        page: {
          asc: [],
          ascs: [],
          countId: "",
          desc: [],
          descs: [],
          hitCount: true,
          maxLimit: null,
          optimizeCountSql: true,
          orders: [
            {
              asc: true,
              column: "",
            },
          ],
          current: 1,
          size: 50,
          searchCount: true,
          pages: null,
          records: [],
          total: 0,
        },
      };
    },
    //判断当前审批人是否为法务审批
    getIsLawApproval() {
      isLawApproval().then((res) => {
        if (res.result) {
          this.isshow = true;
        } else {
          this.isshow = false;
        }
      });
    },
    //审批数量
    getcount() {
      GetApprovalCount().then((res) => {
        if (res.success) {
          setTimeout(() => {
            this.count = res.result;
            this.screenLoading = false;
          }, 500);
        }
      });
    },
    // 获取合同列表
    loadList() {
      this.listLoading = true;
      this.isCheckInfo = false;
      this.dialogVisible = false;
      this.getcount();
      contractApprovalList(this.filter).then((res) => {
        if (res.success) {
          this.listLoading = false;
          this.ListData = res.result.records;
          if (this.filter.favoriteId && this.ListData.length) {
            this.isShowDelBtn = true;
          }
          this.ListData.forEach((element) => {
            element["show"] = false;
          });
          this.filter.page.total = res.result.total;
        }
        this.listLoading = false;
      });
    },
    //获取创建人列表
    loadUserBase() {
      getUserBaseInfos(this.filter).then((res) => {
        if (res.success) {
          this.userList = res.result;
          this.userListCopy = res.result;
        }
      });
    },
    //表单搜索
    submitForm() {
      if (this.filter.id && this.filter.id < 1) {
        this.$message.error("请输入正确的id");
        return false;
      }
      if (this.rangTime && this.rangTime.length > 0) {
        this.filter.scheduleStartTime = this.rangTime[0];
        this.filter.scheduleEndTime = this.rangTime[1];
      } else {
        this.filter.scheduleStartTime = "";
        this.filter.scheduleEndTime = "";
      }
      this.filter.page.current = 1;
      this.loadList();
    },
    //取消新增
    cancel() {
      this.isaddInfo = false;
      this.isCheckInfo = false;
      this.customerDrawerVisible = false;
    },
    //关闭弹窗
    handleClose(done) {
      done();
      this.cancel();
    },
    //保存修改
    changeSaveLoading(val) {
      this.saveloading = val;
    },
    //重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    //客户详情
    toCustomer(comId) {
      this.customerDrawerVisible = true;
      this.customId = comId;
    },
    //审批状态切换
    handleClick(tab, event) {
      this.filter.listType = tab.name;
      this.filter.page.current = 1;
      this.filter.page.size = 50;
      this.loadList();
    },
    //查看
    handleEdit(rid) {
      this.titName = "查看合同信息";
      this.contractId = rid;
      this.isCheckInfo = true;
    },
    filterFun(data) {
      if (data) {
        this.userList = this.userListCopy.filter((item) => {
          if (
            !!~item.name.indexOf(data) ||
            !!~item.name.toLowerCase().indexOf(data.toLowerCase())
          ) {
            return true;
          }
        });
      } else {
        this.userList = this.userListCopy;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../collapsed.less";
.searchM {
  margin-bottom: 5px;
  .el-form-item {
    float: left;
  }
}
.searchL {
  width: calc(100% - 100px);
}
.search {
  height: 35px;
  line-height: 35px;
  :deep(.el-input__inner) {
    height: 35px !important;
    line-height: 35px !important;
    border-color: #e60012 !important;
  }
  :deep(.el-input-group__append) {
    border-color: #e60012 !important;
    background-color: #e60012;
    height: 33px !important;
    line-height: 33px !important;
    color: #fff;
    font-weight: bold;
  }
  :deep(.el-input-group__append:hover) {
    background-color: #cc0000;
  }
}
.other {
  line-height: 35px;
  font-size: 13px;
  float: right;
  span {
    margin: 0;
    cursor: pointer;
    color: #606266;
    &:hover {
      color: #e60012;
    }
  }
  .order {
    padding: 0 8px;
    border-right: 1px solid #e5e5e5;
  }
  .more {
    padding-left: 8px;
    i {
      margin-left: 8px;
    }
  }
}
</style>

<style  lang="scss">
.approval {
  .el-tabs__nav-wrap {
    padding: 0 20px;
  }
  .el-tabs__header {
    margin-bottom: 0px;
  }
}
.contractsApproval {
  .el-table .cell {
    padding: 5px 15px;
  }
}
</style>